import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";
import ProtectedRoute from "./components/ProtectedRoute";

import LoginPage from "./pages/LoginPage";
import DashboardPage from "./pages/DashboardPage";
import ManageNamesPage from "./pages/ManageNamesPage";
import AnalyticsPage from "./pages/AnalyticsPage";
import UserAnalyticsPage from './pages/UserAnalyticsPage';
import BulkUploadPage from "./pages/BulkUploadPage";
import InvitePage from "./pages/InvitePage";

const App = () => {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          {/* Public Route */}
          <Route path="/login" element={<LoginPage />} />
          <Route path="/invite" element={<InvitePage />} />

          {/* Protected Routes */}
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <DashboardPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <DashboardPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/manage-names"
            element={
              <ProtectedRoute>
                <ManageNamesPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/bulk-upload"
            element={
              <ProtectedRoute>
                <BulkUploadPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/analytics"
            element={
              <ProtectedRoute>
                <AnalyticsPage />
              </ProtectedRoute>
            }
          />
          <Route 
            path="/user-analytics" 
            element={
                <ProtectedRoute>
                  <UserAnalyticsPage />
                </ProtectedRoute>
              }
            />
        </Routes>
      </AuthProvider>
    </Router>
  );
};

export default App;