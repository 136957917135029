import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

const InvitePage = () => {
  const [searchParams] = useSearchParams();
  const inviteCode = searchParams.get("code");
  const [showDownload, setShowDownload] = useState(false);

  useEffect(() => {
    if (!inviteCode) return;

    const appUrl = `babyswiper://invite?code=${inviteCode}`; // Custom URL scheme
    const fallbackUrl = `https://vikingclap.com/app-download`; // Your app download page

    // Open the app
    window.location.href = appUrl;

    // If the app is not installed, show the download options after 2.5 seconds
    const timer = setTimeout(() => {
      setShowDownload(true);
    }, 2500);

    return () => clearTimeout(timer);
  }, [inviteCode]);

  return (
    <div style={{ textAlign: "center", padding: "2rem" }}>
      <h2>Connecting to the App...</h2>
      <p>If nothing happens, click the button below:</p>
      <a href={`babyswiper://invite?code=${inviteCode}`} style={{ fontSize: "18px", color: "blue" }}>
        Open in App
      </a>

      {showDownload && (
        <div style={{ marginTop: "20px" }}>
          <h3>Don't have the app?</h3>
          <p>Download it now and join the invite!</p>
          <a href="https://play.google.com/store/apps/details?id=com.babynameapp" style={{ fontSize: "18px", fontWeight: "bold" }}>
            Get it on Google Play
          </a>
          <br />
          <a href="https://apps.apple.com/app/id123456789" style={{ fontSize: "18px", fontWeight: "bold" }}>
            Download on the App Store
          </a>
        </div>
      )}
    </div>
  );
};

export default InvitePage;